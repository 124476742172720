import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
    apiKey: "AIzaSyBF8f7Uc4J2xZxNvgqBwS40DYHxENN_AGk",
    authDomain: "global-crm-954e0.firebaseapp.com",
    projectId: "global-crm-954e0",
    storageBucket: "global-crm-954e0.appspot.com",
    messagingSenderId: "847051064854",
    appId: "1:847051064854:web:7e7a9403c6369164d0d737"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()
